<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
      <!-- 탱크재고 조회 --------------------------------------------------------------------------------------------->
      <BCard>
        <BCard header-tag="header" footer-tag="footer">

          <div slot="header">
            <BIconServer/> <strong> 탱크 재고조회 </strong>
            <div class="card-header-actions">
              <small class="text-muted">탱크별 재고정보를 조회합니다.</small>
            </div>
          </div>

          <BRow class="mb-2">
            <BCol>
              <BInputGroup size="sm">
                <BInputGroupAppend>
                  <BFormSelect size="sm" v-model="tid" @input="getTankData" :options="tankOpts" style="background-color:#2f303a"/>
                </BInputGroupAppend>
              </BInputGroup>
            </BCol>
            <BCol>
              <BInputGroup size="sm">
                <BFormInput type="date" v-model="fromDts" @input="getTankData"></BFormInput>
                <BFormInput type="date" v-model="toDts" @input="getTankData"></BFormInput>
              </BInputGroup>
            </BCol>
            <BCol sm="2" class="text-right">
              <BInputGroup size="sm">
                <BFormSelect size="sm" v-model="$store.state.numOfTankStockItem" :options="[10,15,20,30,50,100]" style="background-color:#2f303a"/>
                <BInputGroupAppend>
                  <BButtonGroup>
                    <BButton size="sm" variant="primary" @click="getTankData"><BIconArrowRepeat/></BButton>
                    <BButton size="sm" variant="info" @click="()=>{this.downloadModalShow = true}"><BIconFileSpreadsheetFill/></BButton>
                  </BButtonGroup>
                </BInputGroupAppend>
              </BInputGroup>
            </BCol>
          </BRow>

          <BRow>
            <BCol>
              <vue-excel-editor
                v-model="rows"
                ref="excelGrid"
                width="100%"
                class="mb-1"
                :page="$store.state.numOfTankStockItem"
                :readonly-style="{backgroundColor:'#4F5040'}"
                @select="selectRecord"
                :localized-label="editorLabel"
                filter-row>
                <vue-excel-column field="_id"        type="string"   key-field invisible/>
                <vue-excel-column field="dt"         type="string"   width="160px" :label="colNm['dt']" :to-text="toLocalTime" readonly/>
                <vue-excel-column field="mid"        type="string"   width="80px" :label="colNm['mid']" readonly/>
<!--                <vue-excel-column field="tid"        type="string"   width="80px" :label="colNm['tid']" readonly/>-->
                <vue-excel-column field="acd"        type="string"   width="100px" :label="colNm['acd']" :to-text="v=>{return v? arCodeMap[v]:'' }" readonly />
<!--                <vue-excel-column field="use"        type="string"   width="80px" :label="colNm['use']" readonly/>-->
                <vue-excel-column field="ohr"        type="number"   width="80px" :label="colNm['ohr']" :to-text="toCommaInt" readonly/>
                <vue-excel-column field="whr"        type="number"   width="80px" :label="colNm['whr']" :to-text="toCommaInt" readonly/>
                <vue-excel-column field="ov"         type="number"   width="80px" :label="colNm['ov']"  :to-text="toCommaInt" readonly/>
                <vue-excel-column field="wv"         type="number"   width="80px" :label="colNm['wv']"  :to-text="toCommaInt" readonly/>
                <vue-excel-column field="ovm"        type="number"   width="80px" :label="colNm['ovm']" :to-text="toCommaInt" readonly/>
                <vue-excel-column field="ovc"        type="number"   width="80px" :label="colNm['ovc']" :to-text="toCommaInt" readonly/>
                <vue-excel-column field="ovcr"       type="number"   width="80px" :label="colNm['ovcr']" :to-text="toCommaInt" readonly/>
                <vue-excel-column field="ovmGl"      type="number"   width="80px" :label="colNm['ovmGl']" :to-text="toCommaInt" readonly/>
                <vue-excel-column field="ovcGl"      type="number"   width="80px" :label="colNm['ovcGl']" :to-text="toCommaInt" readonly/>
                <vue-excel-column field="tvp"        type="number"   width="80px" :label="colNm['tvp']" readonly/>
                <vue-excel-column field="avgTm"      type="number"   width="80px" :label="colNm['avgTm']" readonly/>
                <vue-excel-column field="minTm"      type="number"   width="80px" :label="colNm['minTm']" readonly/>
                <vue-excel-column field="maxTm"      type="number"   width="80px" :label="colNm['maxTm']" readonly/>
                <vue-excel-column field="avgTmF"     type="number"   width="80px" :label="colNm['avgTmF']" readonly/>
                <vue-excel-column field="minTmF"     type="number"   width="80px" :label="colNm['minTmF']" readonly/>
                <vue-excel-column field="maxTmF"     type="number"   width="80px" :label="colNm['maxTmF']" readonly/>
                <vue-excel-column field="tm1"        type="number"   width="60px" :label="colNm['tm1']" readonly/>
                <vue-excel-column field="tm2"        type="number"   width="60px" :label="colNm['tm2']" readonly/>
                <vue-excel-column field="tm3"        type="number"   width="60px" :label="colNm['tm3']" readonly/>
                <vue-excel-column field="tm4"        type="number"   width="60px" :label="colNm['tm4']" readonly/>
                <vue-excel-column field="tm5"        type="number"   width="60px" :label="colNm['tm5']" readonly/>
                <vue-excel-column field="tm6"        type="number"   width="60px" :label="colNm['tm6']" readonly/>
                <vue-excel-column field="isEvt"      type="map"      width="40px" :label="colNm['isEvt']" :options="{true:'Y', false:''}" readonly/>

<!--                <vue-excel-column field="tm7"        type="number"   width="80px" :label="colNm['tm7']" readonly/>-->
<!--                <vue-excel-column field="tm8"        type="number"   width="80px" :label="colNm['tm8']" readonly/>-->
<!--                <vue-excel-column field="tm9"        type="number"   width="80px" :label="colNm['tm9']" readonly/>-->
<!--                <vue-excel-column field="rsi"        type="number"   width="80px" :label="colNm['rsi']" readonly/>-->
<!--                <vue-excel-column field="btr"        type="number"   width="80px" :label="colNm['btr']" readonly/>-->
                <vue-excel-column field="tv"         type="number"   width="80px" :label="colNm['tv']" :to-text="toCommaInt" readonly/>
                <vue-excel-column field="trv"        type="number"   width="80px" :label="colNm['trv']" :to-text="toCommaInt" readonly/>

                <vue-excel-column field="text"       type="string"   width="500px" :label="colNm['text']" readonly/>
                <vue-excel-column field="isErr"      type="map"      width="40px" :label="colNm['isErr']" :options="{true:'Y', false:''}" readonly/>

              </vue-excel-editor>
            </BCol>
          </BRow>


        </BCard>

        <TankLineChart v-if="graphData!==null && tank!==null"
                       style="min-height: 500px;"
                       :tank="tank"
                       :tankData="graphData"
                       :oilColor="oilColors[tank.oilCode]"
                       :key="tankKey"
        />

      </BCard>

    <div v-if="tank">
      <CModal ref="download-modal"
              color="warning"
              title="다운로드 사유 입력"
              :show.sync="downloadModalShow">
        <BInputGroup>
          <BFormInput v-model="downloadReason" debounce="6000" placeholder="5자이상 입력"></BFormInput>
          <BInputGroupAppend>
            <BButton variant="outline-danger" block @click="exportExcel"><BIconDownload/> 다운로드</BButton>
          </BInputGroupAppend>
        </BInputGroup>
      </CModal>
    </div>

  </div>
</template>

<style src="spinkit/spinkit.min.css"></style>

<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
  accessLogging,
  random, iosDtToLocal,
} from '@/common/utils';
import qs from 'querystring';
import moment from "moment";
import {ExcelEditorLabel} from '@/common/constants';
import TankLineChart from "@/views/components/charts/TankLineChart";


const _packetColMap = {
  dt            : '측정일시',   // T_CREATE_DATE
  tid           : '탱크번호', // T_TANK 탱크아이디
  mid           : '장비번호',
  acd           : '관제지역', // Work Group Code (지역코드)
  am            : '측정구분', // T_TYPE =  A=ATG,M=MTG
  ocd           : '유종코드', // T_OIL 유종코드
  use           : '사용용도', // T_USE 사용용도
  tv            : '탱크용량', // T_MAX_VOLUME = tank.tankVolume
  tvp           : '충족률', // T_MAX_VOLUME = tank.tankVolume
  trv           : '실용량', // T_REAL_VOLUME = tank.tankRealVol
  ohr           : '유위_mm', // T_OIL_HEIGHT = tlgPacket.ohr = 유위(보정높이)
  whr           : '수위_mm', // T_WATER_HEIGHT = tlgPacket.whr = 수위(보정높이)
  ov            : '유량_L', // T_OIL_VOLUME = tlgPacket.ov = 유량
  wv            : '수분_L', // T_WATER_VOLUME = tlgPacket.wv = 수분량
  ovm           : '재고_L', // T_MEASURE_VOLUME = tlgPacket.ovm = 실재고
  ovc           : '환산_L', // T_CONVERSION = tlgPacket.ovc = 체적 환산유량
  ovmGl         : '재고_Gl', // T_MEASURE_VOLUME = tlgPacket.ovm = 실재고 Gl
  ovcGl         : '환산_Gl', // T_CONVERSION = tlgPacket.ovc = 체적 환산유량 Gl
  ovcr          : '보정_L', // T_REVISION = tlgPacket.ovc = 체적 환산유량 + 보정
  tm1           : '온도1', // T_TEMP1 = tlgPacket.tm1
  tm2           : '온도2', // T_TEMP2 = tlgPacket.tm2
  tm3           : '온도3', // T_TEMP3 = tlgPacket.tm3
  tm4           : '온도4', // T_TEMP4 = tlgPacket.tm4
  tm5           : '온도5', // T_TEMP5 = tlgPacket.tm5
  tm6           : '온도6', // T_TEMP6 = tlgPacket.tm6
  tm7           : '온도7', // T_TEMP7 = tlgPacket.tm7
  tm8           : '온도8', // T_TEMP8 = tlgPacket.tm8
  tm9           : '온도9',
  avgTm         : '평균온도_C', // T_AVERAGE_TEMP
  maxTm         : '최고온도_C', // T_MAX_TEMP
  minTm         : '최저온도_C', // T_MIN_TEMP
  avgTmF        : '평균온도_F', // T_AVERAGE_TEMP
  maxTmF        : '최고온도_F', // T_MAX_TEMP
  minTmF        : '최저온도_F', // T_MIN_TEMP
  btr           : '배터리', // T_BATTERY = tlgPacket.btr
  rsi           : '신호세기', // T_RSSI = tlgPacket.rsi
  text          : '메시지', // T_COMMENT
  isEvt         : '경보',
  isErr         : '에러',
};


// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };


//----------------------------------------------------------------------------------------------------
export default {
  name: "TankData",
  components: {
    TankLineChart
  },
  data () {
    return {
      selectedRow: null,
      fromDts: moment().subtract(1,'day').format('YYYY-MM-DD'),
      toDts: moment().format('YYYY-MM-DD'),
      tid: '',
      colNm: _packetColMap,
      tank: null,
      graphData: null,
      oilColors: this.$store.state.codeMaps['OIL_COLOR'],
      tankKey: random(0,100000),
      tankOpts : this.$store.state.tanks['opts'],
      maps: this.$store.state.codeMaps,

      tanks: this.$store.state.tanks['codes'],

      editorLabel: ExcelEditorLabel,
      downloadModalShow: false,
      oilCodeMap  : {},
      arCodeMap  : this.$store.state.area['map'],
      arCodeOpts  : this.$store.state.area['opts'],
      downloadReason: '',
      searchOpts: [
        {value:'tid',        text:'탱크아이디'},
        {value:'name',       text:'탱크명'},
        {value:'arCode',     text:'지역코드'},
      ],
      searchField: 'tid',
      searchWord : '',
      progCount: 0,
      totalCount: 0,
      rows: [],

      paramMap: {},
      numOfRecord: 10,

      modalTitle: '',
      tankFormShow: false,

    }

  },
  async created(){
    try{
      console.log( 'tank options--->', this.tankOpts );
      await this.getTankData();
    }catch(err){
      console.log(err);
    }
  },

  computed: {},

  mounted() {
    console.log("--- TankData mounted---------------------");
    // below is not work!
  },

  methods: {

    async showGraph(){

    },
    async getTankData(){
      try{
        if(!this.tid){
          this.tid = this.tankOpts[1].value;
        }


        let qry = '';
        this.paramMap = {
          fromDts: this.fromDts,
          toDts: this.toDts
        };
        if(this.searchWord) this.paramMap[this.searchField] = this.searchWord;
        qry = qs.stringify( this.paramMap );
        console.log( "query-string ---------- ", qry );

        this.rows = [];
        const r = await apiCall('get', `/api/inventory/tank/${this.tid}?${qry}`);

        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`${this.rows.length}건 조회됨`, 'info');
          this.makeGraphData( r.result );
          // console.log( 'task-data-query-result--->', r.result  );
        }
        // this.$refs['excelGrid'].clearFilter();

      }catch(err){
        // console.log( 'getTankList----------------->', err );
        this.toastError(err);
      }

    },

    makeGraphData(rs){
      let ovms = [];
      let ovcs = [];
      let tmps = [];
      let labels = [];
      rs.map(r=>{
        labels.push( moment(r.dt).format('MM/DD HH:mm') )
        ovms.push( r.ovm );
        ovcs.push( r.ovc );
        tmps.push( r.avgTm );
      })

      this.graphData = { labels, ovms, ovcs, tmps }
      this.tank = this.tanks[this.tid];
      this.tankKey = random(0,100000);

      console.log( 'graphData --->', this.graphData );
    },

    async exportExcel(){
      console.log('------------exportExcel----------')
      try{

        const numRecs = this.$refs['excelGrid'].getSelectedRecords().length;

        if(!numRecs){
          this.$refs['download-modal'].hide();
          return await this.alertWarn("선택한 레코드만 파일로 생성됩니다.",'선택 레코드 없음');
        }

        if( !this.downloadReason || this.downloadReason.length < 5 ){
          return await this.alertWarn( "다운로드 사유는 5자 이상 입력해야 합니다.", '사유 없음');
        }


        const {currentRoute} = this.$router;
        const {fields} = this.$refs["excelGrid"];
        const format = 'xlsx';
        const exportSelectedOnly = true;

        const filename = currentRoute.path.split('/').pop()+'-'+moment().format('YYYY-MM-DD');
        const labels = fields.map(i=>{ return i.label })
        const rs = await accessLogging(
          'download',
          currentRoute,
          labels,
          this.downloadReason,
          `레코드수: ${numRecs}, 파일명: ${filename}.${format}`
        );

        if(rs===true){
          this.$refs['excelGrid'].getSelectedRecords().map(i=>{
            i.dt = iosDtToLocal(i.dt);
          })

          this.$refs["excelGrid"].exportTable(format, exportSelectedOnly, filename);
          this.downloadReason = '';
          return true;
        }else{
          await this.alertDanger("ERROR: "+rs.message);
          return false;
        }




      }catch(err){
        console.log( 'exportExcel error', err);
      }finally{
        this.$refs['download-modal'].hide();

      }
    },

    async selectRecord(idx, evt){
      if(!evt){
        // this.tankFormShow = false;
        this.selectedRow = null;
        return;
      }

      this.selectedRow = this.$refs['excelGrid'].getSelectedRecords().pop();



    },








  }
}
</script>
